.navbar {
    padding: 3rem 0;
    position: fixed;
    width: 100%;
    max-height: 12rem;
    z-index: 99999;
    transition: all .3s linear;

    @include media-breakpoint-down(sm) {
        padding: 1rem 0;
    }

    .nav-area {
        display: flex;
        justify-content: space-between;

        .area-left {
            width: 6rem;

            a {
                display: flex;
                align-items: center;
                font-size: 2rem;
                font-weight: 400;
                text-decoration: none !important;
                text-transform: uppercase;

                span {
                    margin-left: map-get($map: $spacers, $key: 2);
                    color:#fff;

                    @media screen and (max-width: 419.89px) {
                        font-size: 1.4rem;
                    }

                    @media screen and (max-width: 319.89px) {
                        display: none;
                    }
                }

                img {
                    height: 50px;
                    object-fit: contain;
                }
            }
        }

        .area-right {
            flex: auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @include media-breakpoint-down(md) {
                background: transparent;
                width: 100%;
                height: 11rem;
                padding: 2rem 3rem;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                position: absolute;
                right: 0;
                top: 0;
            }

            @include media-breakpoint-down(sm) {
                height: 7rem;
                padding: 0rem 1rem;
            }

            a {
                margin-left: 2rem;
                font-size: 2rem;
                font-weight: 400;
                text-transform: uppercase;
                text-decoration: none;
                letter-spacing: .5px;
                animation: animate-menu .2s;

                @include media-breakpoint-down(md) {
                    color: white;
                    margin-left: 0rem;
                    margin-bottom: 2rem;
                    display: none;
                    font-size: 2.5rem;
                }
            }

            a:hover {
                color: #7b0cff !important;
            }

            .sp-menu {
                color: map-get($map: $theme-colors, $key: primary);
                margin-bottom: 2rem;
                padding-top: 1.8rem;
                display: none;
                font-size: 2.5rem;
                transition: all .2s ease-in-out;

                @include media-breakpoint-down(md) {
                    display: block;
                }
            }

            &.sp-show {
                background: rgba($color: #000000, $alpha: .8);
                height: 100vh;
                transition: all .2s ease-in-out;

                a {
                    display: block;
                }

                .sp-menu {
                    color: white;
                }
            }

            .dropdown {
                min-width: 13rem;
                margin-left: 2rem;
                justify-content: flex-end;
        
                @media screen and (max-width: 1023.98px) {
                    min-width: auto;
                    margin-left: 0;
                    position: absolute;
                    top: 3.5rem;
                    right: 10rem;
                }

                @include media-breakpoint-down(sm) {
                    top: 1.5rem;
                    right: 5rem;
                }
            
                .selectbox {
                    background-color: rgba(0 0 0 / 20%);
                    color: white;
                    padding: 6px 8px;
                    font-size: 14px;
                    border: 2px solid #ffffff;
                    border-radius: 4px;
                    user-select: none;
            
                    &--caret {
                        font-size: 16px;
                        margin-left: 2.5rem;
                    }
            
                    &:hover,
                    &:focus,
                    &:active {
                        text-decoration: none;
                        transition: all 0.125s ease-in;
                    }
            
                    &::after {
                        display: none;
                    }
                }
        
                .dropdown-item {
                    min-width: 12rem;
                }

                .dropdown-item  {
                    margin: 0 !important;
                    font-size: 1.4rem;
                    letter-spacing: 0;
                    display: block !important;

                    @include media-breakpoint-down(md) {
                        color: #000;
                    }
                }
        
                img {
                    width: 2rem;
                    object-fit: contain;
                    margin-right: 5px;
                }
            }
        }
    }
}

.navbar-sm {
    background: white;
    padding: 1rem 0;
    box-shadow: 0 -2px 18px 4px rgb(0 0 0 / 40%);

    .nav-area {

        .area-right {
            @include media-breakpoint-down(md) {
                height: 7rem;
                padding-top: 0;
            }

            @media screen and (max-width: 1023.98px) {

                .dropdown {
                    top: 1.5rem;
                }
            }
        }
    }
}

@keyframes animate-menu {
    from {
        display: none;
        transform: scale(0, 0);
    }
    to {
        display: block;
        transform: scale(1, 1);
    }
}
