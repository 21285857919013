$primary-color: #000000;
$red-color: #ea362d;
$primary-bg: #26234b;

.bg-light {
    background-color: #f0f0f0 !important;
}

h2 {
    color: $primary-color;
    margin: 0 auto 5rem;
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
}

h3 {
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 1.2;
    text-transform: capitalize;
}

.store-btn {
    background-color: #fff;
    width: 18rem;
    height: 7rem;
    display: inline-block;
    text-align: center;
    line-height: 7rem;
    border-radius: 3px;
    transition: all .2s ease-in;

    @include media-breakpoint-down(sm) {
        width: 12rem;
        height: 5rem;
        line-height: 5rem;
    }

    &:hover {
        transform: scale(1.06);
        transition: all .2s ease-out;
    }

    &.s2 {
        background-color: $red-color;
    }

    img {
        max-width: 100%;
        height: auto;

        @include media-breakpoint-down(sm) {
            width: 70%;
        }
    }
}

.card {
    color: #666;
    border-radius: .4rem;
    border: none;
    -webkit-transition: box-shadow 0.8s ease-out;
    box-shadow: 0.8px 0.9px 3px #80808045;
    user-select: none;
    cursor: default !important;
    //transition: all .2s ease-out;

    &:hover {
        //transform: scale(1.06);
        //transition: all .2s ease-in;
        -webkit-transition: box-shadow 0.8s ease-out;
        box-shadow: 0 40px 30px 0 #80808045;
    }

    .card-body {
        padding: 3rem 2rem;
    }

    &.no-scale:hover {
        transform: unset;
    }
}

.home-headline {
    font-family: monospace;
    position: absolute;
    bottom: 120px;

    .h4 {
        line-height: 1.4;
    }
}

.app-screen {
    width: auto;
    height: 50rem;
    object-fit: contain;
}

.top-header {
    padding: 22rem 0 10rem 0;

    @include media-breakpoint-down(xs) {
        padding: 12rem 0 8rem 0;
    }

    h1 {
        color: white;
        font-size: 3rem;
        line-height: 1.4;

        span {
            color: $red-color;
        }
    }

    .app-screen {
        @include media-breakpoint-down(md) {
            height: 30rem;
        }
    }

}

.feature {
    // max-width: 80rem;
    margin: 0 auto;

    .card {
        margin: map-get($map: $spacers, $key: 2);

        .card-body {
            padding: 3rem 2rem;

            i {
                color: #fff;
                background-color: $red-color;
                width: 65px;
                height: 65px;
                margin-bottom: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 3rem;
                border-radius: 50%;
            }

            span {
                display: block;
                text-align: center;
                font-size: 1.6rem;
                font-weight: 300;

                &:first-of-type {
                    margin-bottom: 1.5rem;
                    font-size: 2rem;
                    font-weight: 500;
                    line-height: 1.2;
                }
            }
        }
    }
}

.pricing {
    margin: 0 auto;

    .card {
        background-color: rgb(255 255 255 / 80%);
        max-width: 36rem;
        margin: 0 auto;

        .card-header {
            background-color: transparent;
            padding: 2.5rem 2rem;
            border-bottom: 1px solid #ccc;

            span {
                color: map-get($map: $theme-colors, $key: dark);
                display: block;
                text-align: center;
                font-size: 2.4rem;
                font-weight: 600;
                line-height: 1.2;
            }
        }

        .card-body {
            padding: 3rem 1rem;

            span {
                display: block;
                text-align: center;
                font-size: 1.6rem;
                font-weight: 300;
                text-transform: capitalize;
            }

            .price {
                color: $primary-color;
                margin-bottom: 3rem;
                display: block;
                text-align: center;
                font-size: 4rem;
                font-weight: 600;
                line-height: 1.2;
            }

            .info {
                display: flex;
                flex-direction: column;
                align-items: center;

                span {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

.faq {

    .faq-item {
        color: #fff;
        background-color: $primary-bg;
        margin-bottom: 3rem;
        font-weight: 400;
        user-select: none;
        box-shadow: 3px 4px 5px 1px rgba($color: $primary-bg, $alpha: .5);

        &--headline {
            background-color: rgba($color: #fff, $alpha: .2);
            padding: map-get($map: $spacers, $key: 3);
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.8rem;
            line-height: 1.2;
            cursor: pointer;

            &::after {
                color: $primary-color;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                content: "\f054";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                transition: transform 0.2s linear;
                margin-left: map-get($map: $spacers, $key: 1);
            }

            &[aria-expanded="true"]::after {
                transform: rotate(90deg);
                transition: transform 0.2s linear;
            }
        }

        &--description {
            background-color: rgba($color: #fff, $alpha: .1);
            padding: map-get($map: $spacers, $key: 3);
            font-size: 1.6rem;
            line-height: 1.5;

            p:last-child {
                margin-bottom: 0;
            }
        }
    }

    .form-contact {
        background-color: rgb(255 255 255 / 80%);
        padding: map-get($map: $spacers, $key: 4) map-get($map: $spacers, $key: 4);
        box-shadow: 0 0 18px 4px rgba($color: #000, $alpha: .08);
    }
}

.contact {
    font-size: 1.8rem;

    table {
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            font-size: 1.6rem;
        }

        tr td {
            padding: map-get($map: $spacers, $key: 2) map-get($map: $spacers, $key: 3);

            @include media-breakpoint-down(md) {
                padding-left: 0;
            }

            &:first-child {
                width: 99%;

                @include media-breakpoint-down(md) {
                    width: auto;
                }
            }

            &:last-child {
                min-width: 360px;

                @include media-breakpoint-down(md) {
                    min-width: auto;
                }
            }
        }
    }

    .btn {
        min-width: 0;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;

        &:hover {
            transform: scale(1.2);
        }
    }
}

.about {
    max-width: 820px;
    max-height: 30rem;
    margin: 24rem auto 11rem;
    font-size: 1.8rem;
    transform: translate(20px, 0);
    overflow-y: auto;

    @include media-breakpoint-down(md) {
        max-width: 100%;
        transform: unset;
    }

    .card {
        background-color: transparent;
        box-shadow: none
    }

    span {
        color: map-get($map: $theme-colors, $key: dark);
    }
}

.why-us {

    .card {
        margin-bottom: map-get($map: $spacers, $key: 4);
        transition: all .2s ease-out;

        &:hover {
            transform: scale(1.02);
            transition: all .2s ease-in;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .card-body {
            padding: 2rem 3.5rem;

            i {
                color: #fff;
                background-color: $red-color;
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .8rem;
                transform: translate(-50%, 0);
            }

            span {
                display: block;
                font-size: 1.6rem;
                font-weight: 300;

                &:first-of-type {
                    margin-bottom: .5rem;
                    font-size: 2rem;
                    font-weight: 600;
                    line-height: 1.2;
                }
            }
        }
    }

}
