html {
    font-size: 10px;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Pseudo classes
*:focus {
    outline: none !important;
}

a, button, input, textarea, select,
[type="button"], [type="reset"], [type="submit"]
{
    outline: 0 !important;
}

input, textarea, select {
    font-size: 1.4rem !important;
    border: 1px solid #ddd !important;
}

img {
    max-width: 100%;
}

#app {
    min-height: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-size: 1.6rem;

    @include media-breakpoint-down(sm) {
        overflow: hidden;
    }
}

.main-wrapper {
    width: 100%;
    max-width: calc(1060px + #{map-get($map: $spacers, $key: 4) * 2});
    padding: 0 map-get($map: $spacers, $key: 4);
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        padding: 0 map-get($map: $spacers, $key: 2);
    }
}

.form-group {
    margin-bottom: 2rem;
}

.form-control {
    color: #26234b;
    height: calc(2.8rem + 3rem + 2px);
    padding: 1.5rem 2rem;
    font-weight: 600;

    &::placeholder {
        font-weight: 400;
    }
}

.btn {
    min-width: 18rem;
    height: 6rem;
    font-size: 1.8rem;
    box-shadow: 2px 3px 8px rgba($color: #000, $alpha: .3);
    transition: all .2s ease-in;
    
    &:hover {
        transform: scale(1.04);
        transition: all .2s ease-out;
    }
}

.disabled {
    user-select: none;
    pointer-events: none;
}