small-message {
    margin-top: map-get($map: $spacers, $key: 1);
    display: block;
    line-height: 1.2;
    font-size: 80%;

    &:empty {
        margin-top: 0;
        display: none;
    }
}