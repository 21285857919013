/* Variable */
@import "variable";

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";

@import "fonts";
@import "reboot";
@import "misc";

@import 'theme/header';
@import 'theme/payment';
@import 'theme/home';
@import 'theme/footer';