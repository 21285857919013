.footer {
    padding: map-get($map: $spacers, $key: 6) 0;
    font-size: 2rem;

    .branding {
        width: 23rem;
        height: auto;
        object-fit: contain;
        object-position: center;

        @include media-breakpoint-down(md) {
            transform: translate(0, -1rem);
        }

        @include media-breakpoint-down(sm) {
            width: 13rem;
        }
    }

    table {
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            font-size: 1.6rem;
        }

        tr td {
            padding: map-get($map: $spacers, $key: 2) map-get($map: $spacers, $key: 3);

            @include media-breakpoint-down(md) {
                padding-left: 0;
            }

            &:first-child {
                width: 99%;

                @include media-breakpoint-down(md) {
                    min-width: 0;
                    display: none;
                }
            }

            &:last-child {
                @include media-breakpoint-up(lg) {
                    min-width: 360px;
                }
            }
        }
    }

    .btn {
        min-width: 0;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;

        &:hover {
            transform: scale(1.2);
        }
    }
}

