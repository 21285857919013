$theme-colors: ();
$theme-colors: map-merge((
    "primary":    #000000,
    "secondary":  #343551,
    "success":    #239f00,
    "info":       #17a2b8,
    "warning":    #CCB802,
    "danger":     #FF3178,
    "dark":       #09001a
), $theme-colors);

/** Sass Options **/
$font-family-base: 'Share Tech Mono', 'Hanuman', monospace;
$line-height-base:  1.4;
$enable-rounded:  true;
$enable-caret:    true;
$body-color:      #666;
$body-bg:         white;

$border-color:                  #09001a;
$input-border-color:            $border-color;
$nav-tabs-border-color:         $border-color;
$pagination-border-color:       $border-color;
$card-border-color:             $border-color;
$popover-border-color:          $border-color;
$toast-border-color:            $border-color;
$toast-header-border-color:     $border-color;
$modal-content-border-color:    $border-color;
$list-group-border-color:       $border-color;
$thumbnail-border-color:        $border-color;

// Spacing
$mSpacer: .5rem;
$mSpacers: ();
$spacers: map-merge((
    0: 0,
    1: ($mSpacer * 1),
    2: ($mSpacer * 2),
    3: ($mSpacer * 4),
    4: ($mSpacer * 6),
    5: ($mSpacer * 10),
    6: ($mSpacer * 12),
    7: ($mSpacer * 14),
    8: ($mSpacer * 16),
    9: ($mSpacer * 18),
    10: ($mSpacer * 20)
  ), $mSpacers);
